// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDZg-B2mXM0Mh1Br6J-fPMMi3EmIZBAJOY",
  authDomain: "overviewbox-a5960.firebaseapp.com",
  projectId: "overviewbox-a5960",
  storageBucket: "overviewbox-a5960.appspot.com",
  messagingSenderId: "179092402606",
  appId: "1:179092402606:web:c5cc09444ef843db08183f"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export {
    app,
    auth
}