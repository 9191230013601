import {app, auth} from './firebase'
import { useAuthState } from 'react-firebase-hooks/auth';

import { HashRouter, Routes, Route, useParams, Link } from "react-router-dom";
import './App.css'

import Loading from "./template/Loading";

import Home from "./components/Home";
import MainDashboard from "./components/MainDashboard";
import PointsDashboard from './components/PointsDashboard';
import ModulesDashboard from './components/ModulesDashboard';

import MapsMainDashboard from "./components/modules/MapsMainDashboard";
import EventsMainDashboard from './components/modules/EventsMainDashboard';
import PermissionsDashboard from './components/PermissionsDashboard';
import StoreDashboard from './components/StoreDashboard';
import LogsDashboard from './components/LogsDashboard';

let App = () => {
  const [user, loading, error] = useAuthState(auth);
  
  return loading ? ( <Loading /> ) : (
    ( !user ) ? ( 
      <HashRouter>
        <Routes>
          <Route path="*" element={<Home />} />
        </Routes>
      </HashRouter> 
    ) : ( 
      <HashRouter>
        <Routes>
          <Route path="/modules/events" element={<EventsMainDashboard />} />
          <Route path="/modules/maps" element={<MapsMainDashboard />} />
          <Route path="/modules" element={<ModulesDashboard />} />
          <Route path="/store" element={<StoreDashboard />} />
          <Route path="/logs" element={<LogsDashboard />} />
          <Route path="/permissions" element={<PermissionsDashboard />} />
          <Route path="/points" element={<PointsDashboard />} />
          <Route path="*" element={<MainDashboard />} />
        </Routes>
      </HashRouter> 
    )
  );
}

export default App;
