import {app, auth} from '../firebase'
import { signInWithPopup, GoogleAuthProvider, signOut, getAuth } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth';

import { HashRouter, Routes, Route, useParams, Link } from "react-router-dom";
import '../App.css'
import { useState } from 'react';
import { useEffect } from 'react';

const Header = () => {
  const [user, loading, error] = useAuthState(auth);
  const [points, setPoints] = useState({ loading: true, value: 0 })

  useEffect(() => {
    loadPoints()
  }, [])

  let loadPoints = async () => {
    fetch(`https://general-microservice-wldnf2tpna-uc.a.run.app/points`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.stsTokenManager.accessToken
      }
    }).then(async data => 
      setPoints({ loading: false, data: await data.json() }))
  }

  return (
    <div>
      <div className="appHeaderBox">
        <div className="appHeaderWellcome">
          <span>Overviewbox | <b>{user.displayName}</b> <b>({user.email})</b> | {points.loading ? 'Loading...' : points.data.total + ' BoxPoints'} </span>
          <span style={{float: 'right',position: 'absolute',top: 10,right: 10}}>
            <Link to="/"><button className="appContentAddButton">Home</button></Link>
            <Link to="/points"><button className="appContentAddButton">Buy Points</button></Link>
            <Link to="/"><button className="appContentAddButton" onClick={() => signOut(auth)}>Logout</button></Link>
          </span>
        </div>
      </div>
    </div>
  )
}

export default Header;
