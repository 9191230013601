import {app, auth} from '../firebase'
import { useEffect, useState } from "react";
import { useAuthState } from 'react-firebase-hooks/auth';

import { HashRouter, Routes, Route, useParams, Link } from "react-router-dom";
import '../App.css'
import Header from '../template/Header';

let MainDashboard = () => {
  const [user, loading, error] = useAuthState(auth);
  const [mapState, setMapState ] = useState(false);
  const [mapsData, setMapsData] = useState({ loading: true, data: []})
  const updateChannel = new BroadcastChannel("updateChannel");

  return (
    <div className="content">
      <Header />
      
      <div className="appContentBoxOption">
        <div className="appContentTitle">store</div>
        <div className="appContentDescription">buy modules for your overviewbox account using your BoxPoints</div>
        <div className="appContentAdd">
          <Link to="store"><button className="appContentAddButton">Access</button></Link>
        </div>
      </div>
      
      <div className="appContentBoxOption">
        <div className="appContentTitle">permissions</div>
        <div className="appContentDescription">verify permissions for your account</div>
        <div className="appContentAdd">
          <Link to="permissions"><button className="appContentAddButton">Access</button></Link>
        </div>
      </div>
      
      <div className="appContentBoxOption">
        <div className="appContentTitle">modules</div>
        <div className="appContentDescription">access your authorized modules</div>
        <div className="appContentAdd">
          <Link to="modules"><button className="appContentAddButton">Access</button></Link>
        </div>
      </div>

      <div className="appContentBoxOption">
        <div className="appContentTitle">logs</div>
        <div className="appContentDescription">access your logs</div>
        <div className="appContentAdd">
          <Link to="logs"><button className="appContentAddButton">Access</button></Link>
        </div>
      </div>
    </div>
  )
}

export default MainDashboard;
