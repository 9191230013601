import {app, auth} from '../../firebase'
import { initializeApp } from "firebase/app";
import { signInWithPopup, GoogleAuthProvider, signOut, getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { useAuthState } from 'react-firebase-hooks/auth';

import { HashRouter, Routes, Route, useParams, Link } from "react-router-dom";
import '../../App.css'

import Header from '../../template/Header';

let SpecificMapTokens = ({ mapInfo: { id, data }}) => {
  const [user, loading, error] = useAuthState(auth);
  const [mapTokensData, setMapTokensData] = useState({ loading: true });
  const updateChannel = new BroadcastChannel("updateChannel");

  useEffect(() => {
    loadMapTokenData()
  }, [])

  let loadMapTokenData = () => {
    fetch(`https://maps-microservice-overviewbox-com-wldnf2tpna-uc.a.run.app/maps/${id}/tokens`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.stsTokenManager.accessToken
      }
    }).then(async data => 
      setMapTokensData({ loading: false, data: await data.json() }))
  }
  

  let newAPIToken = () => {
    fetch(`https://maps-microservice-overviewbox-com-wldnf2tpna-uc.a.run.app/maps/${id}/tokens`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.stsTokenManager.accessToken
      }
    }).then(async data => { 
      loadMapTokenData()
    })
  }
  
  let openTrackingTool = (token) => {
    let mapsEndpoint = "https://maps.overviewbox.com";
    let pointID = prompt("Please insert the point ID to be used in the tracking");
    window.open(`${mapsEndpoint}/#/tools/tracking/${data.creatorUID}/${id}/${token}/${pointID}`)
  }
  
  let openSearchingTool = (token) => {
    let mapsEndpoint = "https://maps.overviewbox.com";
    window.open(`${mapsEndpoint}/#/tools/searching/${data.creatorUID}/${id}/${token}`)
  }

  let openDataUploadingTool = (token) => {
    let mapsEndpoint = "https://maps.overviewbox.com";
    window.open(`${mapsEndpoint}/#/tools/data/${data.creatorUID}/${id}/${token}`)
  }

  let createItemByAPI = (token) => {
    let newData = {
      lat: 10,
      long: 10,
      description: "TEST"
    };

    fetch(`https://maps-microservice-overviewbox-com-wldnf2tpna-uc.a.run.app/maps/api/${data.creatorUID}/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(newData)
    }).then(async data => {
      alert("Created data: " + JSON.stringify(newData))
      updateChannel.postMessage({ requested: true, origin: "update/tokens", area: "maps" })
    })
    
  }

  return (
    <div className="appContentItemDetails">
        <div className="appContentInformation">
          you can use the public API sending POST requests with the JSON body containing the properties 'lat', 'long' and 'description'. You can also add an 'ID' property to update an existent element. Send the API Token in the 'Authorization' header with the format 'Bearer &lt;API Token&gt;'
          <ul>
            <li>https://maps-microservice-overviewbox-com-wldnf2tpna-uc.a.run.app/maps/api/{data.creatorUID}/{id}</li>
          </ul>
        </div>
          <div className="appContentItemToolbar">
            <div className="appContentEditButton">
              <button className="appButtonEdit" onClick={() => newAPIToken()}>New API Key</button>
            </div>
          </div>
        { mapTokensData.loading ? (
          <div>
            loading...
          </div>
        ) : (
          <div className="appContentTable">
            <div className="appContentItemRowHeader">
              <div className="appContentItemInputHeader">Index</div>
              <div className="appContentItemInputHeader">Key</div>
            </div>
            {(mapTokensData && mapTokensData.data && mapTokensData.data.apiTokens) ? 
              (mapTokensData.data.apiTokens.length > 0 ? mapTokensData.data.apiTokens.map(({token}, index) => (
              <div className="appContentItemRowContent">
                <input className="appContentItemInputContent" disabled value={index} />
                <input className="appContentItemInputContent" disabled value={token} />
                <input className="appContentItemInputButton" type="button" onClick={() => createItemByAPI(token)} value={'Test Item Creation'}/>
                <input className="appContentItemInputButton" type="button" onClick={() => openTrackingTool(token)} value={'Track a point now'}/>
                <input className="appContentItemInputButton" type="button" onClick={() => openSearchingTool(token)} value={'Search Points'}/>
                <input className="appContentItemInputButton" type="button" onClick={() => openDataUploadingTool(token)} value={'Data uploading tool'}/>
              </div>
            )) : (
              <div>
                please create an API Token
              </div>
            )) : <>please create an API Token</>}
          </div>
        )}
      </div>
  )
}

let SpecificMap = ({ mapInfo: { id, data }}) => {
  const [user, loading, error] = useAuthState(auth);
  const [modifiedData, setModifiedData] = useState(data.data);
  const [viewData, setViewData] = useState(false);
  const [rawEdit, setRawEdit] = useState(false);
  const [viewAsCSV, setViewAsCSV] = useState(false);
  const [modifiedJSON, setModifiedJSON] = useState('');
  const [modifiedCSV, setModifiedCSV] = useState('');
  const [formattingError, setFormattingError] = useState(false);
  const [formattingErrorMessage, setFormattingErrorMessage] = useState('');
  const [externalSources, setExternalSources] = useState(false);
  const updateChannel = new BroadcastChannel("updateChannel");

  let newItemTemplate = {
    lat: 8,
    long: 8,
    description: 'Example position',
    id: ''
  }

  navigator.geolocation.getCurrentPosition((position) => {
    newItemTemplate = {
      ...newItemTemplate,
      lat: position.coords.latitude,
      long: position.coords.longitude,
    }
  });

  let modify = (index, type, event) => {
    let newValue = event.target.value;
    let finalModifiedVersion = [...modifiedData];
    finalModifiedVersion[index][type] = newValue;
    setModifiedData([...finalModifiedVersion]);
  }

  let addRow = (index) => {
    let finalModifiedVersion = [
      ...modifiedData.slice(0, index + 1), 
      newItemTemplate, 
      ...modifiedData.slice(index + 1, modifiedData.length) 
    ];

    setModifiedData([...finalModifiedVersion]);
  } 
  

  let removeRow = (index) => {
    let finalModifiedVersion = [
      ...modifiedData.slice(0, index), 
      ...modifiedData.slice(index + 1, modifiedData.length) 
    ];

    setModifiedData([...finalModifiedVersion]);
  } 
  
  let updateTitle = () => {
    const title = window.prompt("Please insert the new map title");

    fetch(`https://maps-microservice-overviewbox-com-wldnf2tpna-uc.a.run.app/maps/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.stsTokenManager.accessToken
      },
      body: JSON.stringify({ title })
    }).then(async data => 
      updateChannel.postMessage({ requested: true, origin: "update/title", area: "maps" }))
  }
  
  let updateMapVisibility = (publicStatus) => {
    fetch(`https://maps-microservice-overviewbox-com-wldnf2tpna-uc.a.run.app/maps/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.stsTokenManager.accessToken
      },
      body: JSON.stringify({ public: publicStatus })
    }).then(async data => 
      updateChannel.postMessage({ requested: true, origin: "update/mapVisibility", area: "maps" }))
  }

  let updateData = () => {
    fetch(`https://maps-microservice-overviewbox-com-wldnf2tpna-uc.a.run.app/maps/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.stsTokenManager.accessToken
      },
      body: JSON.stringify({ data: modifiedData })
    }).then(async data => 
      updateChannel.postMessage({ requested: true, origin: "update/data", area: "maps" }))
  }

  const openMap = ({ id, data }) => {
    window.open(`https://maps.overviewbox.com/#/${data.creatorUID}/${id}`)
  }

  let JSONtoCSV = (jsonObject) => {
    try {
      let separator = ';';
      let csv = '';
      let columns = Object.keys(newItemTemplate);
      csv += columns.join(separator) + separator + '\n';
      jsonObject.map((dataValue, index) => {
        columns.map((key) => {
          csv += (dataValue[key] || '') + separator;
        });

        csv += '\n';
      });

      return csv;
    } catch(e) {
      setFormattingError(true)
      setFormattingErrorMessage(e.message)
      return false;
    }
  }

  useEffect(() => {
    setModifiedCSV(JSONtoCSV(modifiedData))
    setModifiedJSON(JSON.stringify(modifiedData))
  }, [modifiedData])

  let CSVtoJSON = (csvValue) => {
    try {
      let jsonObject = [];

      let csvLines = csvValue.split(';\n');
      let csvHeader = csvLines[0].split(';');

      delete csvLines[0];
      csvLines.map(lineInfo => {
        let jsonElementObject = {};
        let csvLineData = lineInfo.split(';')
        csvLineData.map((value, index) => {
          if(csvHeader[index]){
            jsonElementObject[csvHeader[index]] = value;
          }
        })

        if(Object.keys(jsonElementObject).length == csvHeader.length){
          jsonObject.push(jsonElementObject)
        }
      })

      return jsonObject;
    } catch(e) {
      console.log(e)
      setFormattingError(true)
      return false;
    }
  }

  const defineModifiedRawJSON = (e) => {
    let newValue = e.target.value;
    setModifiedJSON(newValue)
    
    let jsonData = false;

    try {
      jsonData = JSON.parse(newValue)
      setModifiedData(jsonData)
      setFormattingError(false)
    } catch(e) {
      console.log(e)
      setFormattingError(true)
    }
  }

  const defineModifiedRawCSV = (e) => {
    let newValue = e.target.value;
    setModifiedCSV(newValue)
    let jsonData = CSVtoJSON(newValue)

    if(jsonData){
      setModifiedData(jsonData)
      setFormattingError(false)
    }
  }

  return (
    <div className="appContentItem">
      <div className="appContentItemButtons">
        <div className="appContentEditButton">
          <button className="appButtonEdit" onClick={() => setViewData(!viewData)}>view Data</button>
        </div>
        <div  className="appContentMapButton">
          <button className="appButtonMap" onClick={() => openMap({ id, data})}>access public map</button>
        </div>
        <div  className="appContentMapButton">
          <button className={`appButtonMap ${data.public && 'buttonPrivate'}`} onClick={() => updateMapVisibility(!data.public)}>
            {data.public ? "turn map private" : "turn map public"}
          </button>
        </div>
        <div className="appContentEditButton">
          <button className="appButtonEdit" onClick={() => setExternalSources(!externalSources)}>external sources</button>
        </div>
      </div>
      <div className="appContentItemData">
        <div className="appContentItemId">{id}</div>
        <div className="appContentItemDate">{new Date(data.createdAt).toLocaleString()}</div>
        <div className="appContentItemTitle">{data.title}</div>
      </div>
      {viewData && (
          <div className="appContentItemDetails">
            <div className="appContentItemToolbar">
              <div className="appContentEditButton">
                <button className="appButtonEdit" onClick={() => updateData()}>save Data</button>
              </div>
              <div className="appContentEditButton">
                <button className="appButtonEdit" onClick={() => updateTitle()}>update Title</button>
              </div>
              <div className="appContentEditButton">
                <button className="appButtonEdit" onClick={() => addRow(-1)}>add new row</button>
              </div>
              <div className="appContentEditButton">
                <button title={formattingErrorMessage} className={"appButtonEdit " + (formattingError && 'errorBtn')}  onClick={() => setRawEdit(!rawEdit)}>raw edit 
                  {formattingError && (" - with errors")}</button>
              </div>
            </div>
            { rawEdit ?  (
                <div className="appContentTable">
                  <div className="appContentItemRowContent">
                    <input className="appContentItemInputButton" type="button" onClick={() => setViewAsCSV(!viewAsCSV)} value={'see as CSV'}/>
                  </div>
                  <div className="appContentItemRowContent">
                    {viewAsCSV ? (
                      <textarea className="appContentItemInputContent" onChange={(e) => setModifiedCSV(e.target.value)} onBlur={(e) => defineModifiedRawCSV(e)}
                        value={modifiedCSV}>
                      </textarea>
                    ) : (
                      <textarea className="appContentItemInputContent" onChange={(e) => setModifiedJSON(e.target.value)} onBlur={(e) => defineModifiedRawJSON(e)}
                        value={modifiedJSON}>
                      </textarea>
                    )}
                  </div>
                </div>
            ) : (
                <div className="appContentTable">
                  <div className="appContentItemRowHeader">
                    <div className="appContentItemInputHeader">Index</div>
                    <div className="appContentItemInputHeader">ID</div>
                    <div className="appContentItemInputHeader">Latitude</div>
                    <div className="appContentItemInputHeader">Longitude</div>
                    <div className="appContentItemInputHeader">Description</div>
                  </div>
                  {modifiedData.map(({id, lat, long, description}, index) => (
                    <div className="appContentItemRowContent">
                      <input className="appContentItemInputContent" disabled value={index} />
                      <input className="appContentItemInputContent" onChange={(e) => modify(index, 'id', e)} value={id} />
                      <input className="appContentItemInputContent" onChange={(e) => modify(index, 'lat', e)} value={lat} />
                      <input className="appContentItemInputContent" onChange={(e) => modify(index, 'long', e)} value={long} />
                      <input className="appContentItemInputContent" onChange={(e) => modify(index, 'description', e)} value={description}/>
                      <input className="appContentItemInputButton" type="button" onClick={() => addRow(index)} value={'Add new row'}/>
                      <input className="appContentItemInputButton" type="button" onClick={() => removeRow(index)} value={'Remove row'}/>
                    </div>
                  ))}
                </div>
            )}
        </div>
      )}

      {externalSources && (
          <SpecificMapTokens mapInfo={{ data, id }} />
      )}
    </div>
  )
}

let AllMaps = ({ mapsData }) => {
  const [user, loading, error] = useAuthState(auth);

  return (
    <div className="appContentListData">
      
      {mapsData.loading && (
        <div>
          loading...
        </div>
      )}

      {(loading && mapsData.data && mapsData.data.length == 0) && (
          <div>
            please register your first map ;)
          </div>
        )}

      {(mapsData.data && mapsData.data.length > 0 && mapsData.data.map((mapInfo) => (
            <SpecificMap mapInfo={mapInfo} />
          ))
      )}
    </div>
  )
}

let MapsMainDashboard = () => {
  const [user, loading, error] = useAuthState(auth);
  const [mapState, setMapState ] = useState(false);
  const [mapsData, setMapsData] = useState({ loading: true, data: []})
  const updateChannel = new BroadcastChannel("updateChannel");

  
  let loadMaps = () => {
    fetch('https://maps-microservice-overviewbox-com-wldnf2tpna-uc.a.run.app/maps', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.stsTokenManager.accessToken
        }
      }).then(async data => {
        setMapsData({ loading: false, data: await data.json(), updatedIn: +(new Date) })
        updateChannel.postMessage({ loaded: true, area: 'maps' })
      })
  }

  const addMap = () => {
    fetch('https://maps-microservice-overviewbox-com-wldnf2tpna-uc.a.run.app/maps', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.stsTokenManager.accessToken
      }
    }).then(async data => 
      updateChannel.postMessage({ requested: true, origin: "create", area: "maps" }))
  }

  useEffect(() => {
    loadMaps()

    updateChannel.onmessage = (messageEvent) => {
      const data = messageEvent.data
      if(data.requested){
        if(data.area == "maps") {
          setMapState('updating data...');
          console.log(mapsData)
          setMapsData({ ...mapsData, loading: true });
          loadMaps();
        }
      } 

      if (data.loaded) {
        if(data.area == "maps") {
          setMapState(false);
        }
      }
    }
  }, [])

  return (
    <div className="content">
      <Header />
      
      <div className="appContentBox">
        <div className="appContentTitle">maps</div>
        <div className="appContentDescription">see all maps in your account {mapState}</div>
        <div className="appContentAdd">
          <button className="appContentAddButton" onClick={() => addMap()}>create map</button>
        </div>
        <div className="appContentList">
          <AllMaps mapsData={mapsData}/>
        </div>
      </div>
    </div>
  )
}

export default MapsMainDashboard;
