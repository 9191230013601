import {app, auth} from '../firebase'
import { signInWithPopup, GoogleAuthProvider, signOut, getAuth } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth';

import '../App.css'

let Home = () => {
  const [user, loading, error] = useAuthState(auth);

  let loginUser = async () => {
    const provider = new GoogleAuthProvider();
    await signInWithPopup(auth, provider)
  }

  return (
    <div className="content">
      <div className="appInfoBox">
        <div className="appTitle">overview<b>box</b></div>
        <div className="appDescription">a <b>box</b> of tools for your data</div>
        <div className="appLogIn">
          <button className="authButton" onClick={() => loginUser()}>Login with Google Account</button>
        </div>
      </div>
      
      <div className="appPublicBox">
        <div className="appPublic">for <b>individuals</b></div>
        <div className="appPublic">for <b>students</b></div>
        <div className="appPublic">for <b>researches</b></div>
        <div className="appPublic">for <b>companies</b></div>
      </div>
      
      <div className="appDemonstrationBox">
        <div className="appDemonstration">easily create <b>maps</b> that matters</div>
      </div>

      <div className="appFooter">
        overview<b>box</b> &copy; {(new Date).getFullYear()}
      </div>
    </div>
  )
}

export default Home;
