import {app, auth} from '../firebase'
import { initializeApp } from "firebase/app";
import { signInWithPopup, GoogleAuthProvider, signOut, getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { useAuthState } from 'react-firebase-hooks/auth';

import { HashRouter, Routes, Route, useParams, Link } from "react-router-dom";
import '../App.css'

import Header from '../template/Header';

let ModulesDashboard = () => {
  const [user, loading, error] = useAuthState(auth);
  const [permissions, setPermissions] = useState({ loading: true, data: [] })

  useEffect(() => {
    loadPermissions()
  }, [])
  
  let loadPermissions = async () => {
    fetch(`https://general-microservice-wldnf2tpna-uc.a.run.app/modules`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.stsTokenManager.accessToken
      }
    }).then(async data => 
      setPermissions({ loading: false, data: await data.json() }))
  }

  return (
    <div className="content">
      <Header />
      
      <div className="appContentBox">
        <div className="appContentTitle">modules</div>
        <div className="appContentDescription">see all modules in your account</div>
        <div style={{ borderTop: '1px solid rgb(40, 40, 40)', marginTop: 20, padding: 20 }}>
          {
            permissions.loading ? "Loading..." : 
              permissions.data && permissions.data.map(({ id, data }) => (
                <div>
                  <b>{id}</b>
                  {Object.keys(data).map((key) => (
                    <div>
                      {key} ({String(data[key])})
                    </div>
                  ))}
                  <Link to={data['link']}><a>Access</a></Link>
                </div>
              ))
          }
        </div>
      </div>
    </div>
  )
}

export default ModulesDashboard;