import {app, auth} from '../firebase'
import { useEffect, useState } from "react";
import { useAuthState } from 'react-firebase-hooks/auth';

import { HashRouter, Routes, Route, useParams, Link } from "react-router-dom";
import '../App.css'
import Header from '../template/Header';

let PointsDashboard = () => {
  const [user, loading, error] = useAuthState(auth);
  const [history, setHistory] = useState({ loading: true, data: []})

  useEffect(() => {
    loadHistory();
  }, [])

  let loadHistory = () => {
    fetch(`https://general-microservice-wldnf2tpna-uc.a.run.app/history`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.stsTokenManager.accessToken
      }
    }).then(async data => 
      setHistory({ loading: false, data: await data.json()}))
  }

  let transfer = () => {
    let value = prompt("Please define the value to send");
    let email = prompt("Please define the user e-mail");
    
    fetch(`https://general-microservice-wldnf2tpna-uc.a.run.app/transfer`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.stsTokenManager.accessToken
      },
      body: JSON.stringify({ email, value })
    }).then(async data => 
      alert("Sent"))
  }

  return (
    <div className="content">
      <Header />
      
      <div className="appContentBoxOption" style={{padding: 80}}>
        <div className="appContentTitle">your points</div>
        <div className="appContentDescription" style={{fontSize: 60}}>buy itens using the overviewbox platform</div>
        <div className="appContentDescription">Please use the same e-mail used in your overviewbox account or your points will not be credited</div>
        <div className="appContentAdd">
          <a href="https://sacola.pagseguro.uol.com.br/a170b4de-3be2-44d0-9ec0-014c63523258" target='_blank'><button className="appContentAddButton" style={{fontSize: 30, padding: 15, marginTop: 20}}>Buy now</button></a>
        </div>
      </div>
      
      <div className="appContentBoxOption" style={{padding: 40}}>
        <div className="appContentTitle">send points</div>
        <div className="appContentDescription" style={{fontSize: 40}}>Want to send points for someone? Use the user e-mail</div>
        <div className="appContentAdd">
          <button className="appContentAddButton" onClick={() => transfer()} style={{fontSize: 20, padding: 15, marginTop: 20}}>Send points</button>
        </div>
      </div>
      
      
      <div className="appContentBoxOption">
        <div className="appContentTitle">history</div>
        <div className="appContentDescription">your activity history with points</div>
      </div>

      <table className="appTable">
        <tr>
          <th>ID</th>
          <th>Type</th>
          <th>Value</th>
          <th>Description</th>
          <th>Is Transfer?</th>
          <th>Product ID</th>
          <th>Sender E-mail</th>
          <th>Receiver E-mail</th>
          <th>Transaction ID</th>
        </tr> 
        {history.data.map(({
            id, 
            data: {
              type, 
              value, 
              description, 
              isTransfer, 
              productId, 
              sender_email, 
              receiver_email, 
              transactionId
            }
          }) => (
            <tr>
              <td>{id}</td>
              <td>{type}</td>
              <td>{value}</td>
              <td>{description}</td>
              <td>{isTransfer}</td>
              <td>{productId}</td>
              <td>{sender_email}</td>
              <td>{receiver_email}</td>
              <td>{transactionId}</td>
            </tr>
          ))}
      </table>
    </div>
  )
}

export default PointsDashboard;
